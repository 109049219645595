import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>DrupalCamp Pune 2024</h1>
        <p>October 19th & 20th 2024.</p>
        <a href="https://calendar.google.com/calendar/render?action=TEMPLATE&text=DrupalCamp Pune&details=Follow us for more updates<ul><li><a href='https://www.drupal.org/project/punedrug'>Drupal.org</a></li><li><a href='https://www.linkedin.com/company/drupal-pune'>LinkedIn</a></li><li><a href='https://twitter.com/drupalcamppune'>Twitter/X</a></li></ul>&dates=20241019T100000/20241020T170000&location=Pune" target="_blank" rel="noreferrer">Add to Calendar</a>
        <p className="small"><a href="https://linktr.ee/drupalpune">Follow us</a> for updates. Details coming soon..</p>
      </header>
    </div>
  );
}

export default App;
